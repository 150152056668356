<template>
	<div class="row">
		<div class="col-12">
			<div class="card mb-2 homeBg">
				<div class="d-flex align-items-center p-4">
					<div class="align-items-center justify-content-center me-2">
						<img src="@/assets/images/logo-icon.png" alt="Mooli Logo" class="m-2 me-4" width="60">
					</div>
					<h2 class="font-30 mt-0 mb-0">CAROLINA<span class="d-none d-sm-inline"><small class="mx-2"><i class="fas fa-horizontal-rule font-17"></i></small>GERENCIAL</span></h2>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'

export default {
	name: 'Página_Inicial',
   computed: {
      ... mapState({
         tokenSessao: state => state.tokenSessao,
         dadosUsuario: state => state.dadosUsuario
      })
   },
	mounted() {
		this.$axios.interceptors.request.use((config) => {
			$('#search-nav .dropdown-toggle').dropdown('hide');

			if (this.tokenSessao != null && this.dadosUsuario != null) {
				config.headers.Authorization = 'Bearer '+ this.tokenSessao +'@@@'+ this.dadosUsuario.usuarioEmail;
			}

			return config;
		});
	}
}

</script>